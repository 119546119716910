var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(56, "Vehicle") } },
                [
                  _c("v-select", {
                    staticClass: "mb-2",
                    attrs: {
                      options: _vm.vehiculesOptionsMap,
                      disabled: _vm.disabled,
                      placeholder: _vm.FormMSG(547, "Select a vehicule"),
                    },
                    on: { input: _vm.handleChange, search: _vm.handleSearch },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({ search, searching }) {
                          return [
                            searching
                              ? [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(1, "No results for")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                  _c("em", [_vm._v(_vm._s(search))]),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t. " +
                                      _vm._s(_vm.FormMSG(2, "You can add it")) +
                                      ".\n\t\t\t\t\t\t"
                                  ),
                                ]
                              : _c("em", { staticStyle: { opacity: "0.5" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.FormMSG(
                                          3,
                                          "Start typing to search for a vehicule"
                                        )
                                      ) +
                                      ". "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedVehicule,
                      callback: function ($$v) {
                        _vm.selectedVehicule = $$v
                      },
                      expression: "selectedVehicule",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.canAddAndEdit && _vm.canDoModification
            ? _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "pt-28 select_items_filter_wrapper",
                    class: { has_two_btn: _vm.isCanModifyVehicule },
                  },
                  [
                    _c("div", { staticClass: "actions_holder" }, [
                      _c(
                        "div",
                        { staticClass: "supplier_actions_btns has_two_btn" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", disabled: _vm.disabled },
                              on: { click: _vm.handleClickAddButton },
                            },
                            [
                              !_vm.isCanModifyVehicule
                                ? _c(_vm.getLucideIcon("PlusCircle"), {
                                    tag: "component",
                                    staticStyle: { "margin-bottom": "2px" },
                                    attrs: { color: "#FFFFFF", size: 18 },
                                  })
                                : _vm._e(),
                              _vm.isCanModifyVehicule
                                ? _c(_vm.getLucideIcon("Edit"), {
                                    tag: "component",
                                    staticStyle: { "margin-bottom": "2px" },
                                    attrs: { color: "#FFFFFF", size: 18 },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                disabled: _vm.disabled,
                                block: "",
                                variant: "outline-primary",
                              },
                              on: { click: _vm.handleClickAddButton },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.isCanModifyVehicule
                                      ? _vm.FormMSG(125, "Edit")
                                      : _vm.FormMSG(2332234, "Add")
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("VehiculesModal", {
        attrs: {
          "edit-data": _vm.currVehiculeEditData,
          "curr-search": _vm.currSearch,
        },
        on: { submit: _vm.handleVehiculeSubmit },
        model: {
          value: _vm.isCarCrudCarModalOpen,
          callback: function ($$v) {
            _vm.isCarCrudCarModalOpen = $$v
          },
          expression: "isCarCrudCarModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }