<template>
	<div class="transport-address-holder-component">
		<b-row class="middle">
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="formLabel">
					<b-form-select
						id="selecting-address-type"
						v-model="addressTypePayload"
						value-field="code"
						text-field="label"
						:disabled="disabled"
						:placeholder="FormMSG(333, 'Chose address mode')"
						:options="addressOptionsMap"
						@change="handleAddressPayloadChange"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="9" lg="9" xl="9" v-if="isDefaultAddressType && !forMyAddress">
				<DynamicAddress
					v-for="(item, i) in addressFiller"
					:key="i"
					hide-remove-btn
					show-clear-address-btn
					:params="item"
					:disabled="disabled"
					:address-id="directionPayload[transportAddressId]"
					@change="handleDynamicAddressChange"
				/>
			</b-col>
			<b-col sm="12" md="9" lg="9" xl="9" v-if="!isDefaultAddressType && !forMyAddress">
				<b-row class="pt-13">
					<b-col sm="12" md="7" lg="7" xl="7">
						<v-select
							v-model="selectedTypeOption"
							:disabled="disabled || disableSelect"
							:placeholder="FormMSG(39933, 'Chose address mode')"
							:options="addressOptionsListMap"
							@input="handleAddressSelectedChange"
						/>
					</b-col>
					<b-col sm="12" md="5" lg="5" xl="5">
						<div class="d-inline-block" :class="{ 'mt-3': $screen.width < 576 }">
							<span v-if="directionPayload[transportAddressId] !== 0" class="address-selected fs-11 fw-700">
								{{ rendAddressSelected }}
							</span>
							<span class="fs-11 fw-700" v-else>...</span>
						</div>
					</b-col>
				</b-row>
			</b-col>

			<b-col sm="12" md="9" lg="9" xl="9" v-if="forMyAddress">
				<span v-if="rendAddressForMyAddress" class="address-selected fs-14 fw-700">
					{{ rendAddressForMyAddress }}
				</span>
				<span class="fs-11 fw-700" v-else>...</span>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { getObjectFromValue, isNil, objReducer, omit } from '~utils';
import { addressOptions, possibleAddressKeys, transportsQueries, transportTypeAddressIds } from '@/modules/transports/utils';
import { getAddress } from '@/cruds/address.crud';
import { addressFormatter } from '@/shared/fomaters';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';

import DynamicAddress from '@/modules/transports/components/DynamicAddressField';

const payloadDefault = {
	fromAddressId: 0,
	fromLocationId: 0,
	fromUserId: 0,
	toAddressId: 0,
	toLocationId: 0,
	toUserId: 0
};
const payloadKeys = Object.keys(payloadDefault);

export default {
	name: 'AddressMakerComponent',
	components: { DynamicAddress },
	mixins: [languageMessages],
	props: {
		editData: {
			type: Object,
			required: true,
			default: () => {}
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		type: {
			type: String,
			required: true,
			default: 'from',
			validator: (v) => ['from', 'to'].includes(v)
		},
		showMyAddress: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		const addressKeys = possibleAddressKeys[this.type];
		return {
			directionPayload: Object.assign({}, payloadDefault),
			addressOptionsListMap: [],

			addressKeys,
			addressOptions: objReducer(addressKeys, addressOptions),
			transportAddressId: transportTypeAddressIds[this.type],
			selectedAddress: null,
			selectedAddressType: addressOptions[addressKeys[0]],
			defaultAddressType: addressOptions[addressKeys[0]].code,
			addressTypePayload: addressOptions[addressKeys[0]].code,

			selectedTypeOption: null,

			rendAddressSelected: null,

			forMyAddress: false,
			rendAddressForMyAddress: null
		};
	},
	computed: {
		disableSelect() {
			if (this.disabled) {
				return true;
			} else {
				if (this.addressTypePayload === 'fromMyAddress' || this.addressTypePayload === 'toMyAddress') {
					return true;
				}

				return false;
			}
		},
		formLabel() {
			if (this.type === 'from') {
				return this.FormMSG(26, 'From');
			} else if (this.type === 'to') {
				return this.FormMSG(27, 'To');
			}
		},
		/**
		 * @return {Array}
		 */
		addressOptionsMap() {
			const _a = this.addressOptions;
			let result = Object.keys(_a).map((key, i) => ({
				..._a[key],
				label: this.FormMSG(123 + i, _a[key].label)
			}));

			if (this.showMyAddress) {
				if (this.type === 'to') {
					result.push({
						code: 'toMyAddress',
						label: this.FormMSG(56, 'My address'),
						queryName: '',
						transportField: '',
						objectGqlKey: ''
					});
				} else {
					result.push({
						code: 'fromMyAddress',
						label: this.FormMSG(56, 'My address'),
						queryName: '',
						transportField: '',
						objectGqlKey: ''
					});
				}
			}

			return result;
		},

		/**
		 * @return {Boolean}
		 */
		isDefaultAddressType() {
			return this.selectedAddressType.code === this.defaultAddressType;
		},

		/**
		 * @return {Array}
		 */
		addressFiller() {
			return [
				{
					name: this.transportAddressId,
					title: this.type === 'from' ? this.FormMSG(23232, 'From address') : this.FormMSG(23212332, 'To address'),
					address: this.type === 'from' ? 'fromAddress' : 'toAddress'
				}
			];
		}
	},
	watch: {
		editData: {
			/**
			 * @param {Object} data
			 */
			handler(data) {
				this.directionPayload[this.transportAddressId] = data[this.transportAddressId];
			},
			deep: true
		}
	},
	created() {
		this.initEditMode();
		this.initAddressText();
	},
	methods: {
		initEditMode() {
			this.directionPayload = {
				...this.directionPayload,
				...objReducer(payloadKeys, this.editData)
			};
			this.initSelectedType(this.directionPayload);
		},

		async initAddressText() {
			if (this.directionPayload[this.transportAddressId] === 0) return;
			const _a = await getAddress(this.directionPayload[this.transportAddressId]);
			this.rendAddressSelected = addressFormatter(_a);
		},

		initSelectedType(payload) {
			const aId = payload[this.transportAddressId];
			const assigned = this.getAssignedAddressdirectionPayload(payload);
			this.buildAddressTypeOptionsList(assigned);
		},

		setSelectedAddressType(code) {
			this.selectedAddressType = this.addressOptions[code];
			this.addressTypePayload = this.selectedAddressType.code;
		},

		/**
		 *
		 *
		 * HANDLERS
		 *
		 *
		 */

		/**
		 * @param {Event} $event
		 * @return {Emitter}
		 */
		handleDynamicAddressChange($event) {
			this.$emit('change', {
				key: null,
				addressId: parseInt($event.id),
				value: null
			});
		},

		async handleAddressSelectedChange({ addressId, code }) {
			const _a = await getAddress(addressId);
			this.rendAddressSelected = addressFormatter(_a);

			this.$emit('change', {
				key: this.selectedAddressType.transportField,
				addressId,
				value: parseInt(code)
			});
		},

		/**
		 * @param {String} code
		 */
		async handleAddressPayloadChange(code) {
			if (code === 'toMyAddress' || code === 'fromMyAddress') {
				this.forMyAddress = true;

				const address = await getAddress(store.state.myProfile.addressID);
				this.rendAddressForMyAddress = addressFormatter(address);

				this.$emit('change', {
					key: this.type === 'to' ? 'toUserId' : 'fromUserId',
					addressId: store.state.myProfile.addressID,
					value: store.userID()
				});
			} else {
				this.forMyAddress = false;
				this.selectedAddressType = this.addressOptions[code];
				this.rendAddressSelected = null;
				const assignment = this.addressOptions[code];

				if (assignment.queryName !== 'address') {
					const list = await transportsQueries[assignment.queryName]();
					this.addressOptionsListMap = this.selectedTypeCleanListMap(assignment, list);
					this.selectedTypeOption = null;
				}

				this.resetData();

				this.setSelectedAddressType(assignment.code);
				await this.initAddressText();
			}
		},

		/**
		 *
		 *
		 * HELPERS
		 *
		 *
		 */

		/**
		 * @param {Object} payload
		 * @return {Object}
		 */
		getAssignedAddressdirectionPayload(payload) {
			const res = {};
			this.addressKeys.forEach((k) => {
				const key = addressOptions[k].transportField;
				if (payload[key] !== 0) res[key] = payload[key];
			});
			return omit([this.transportAddressId], res);
		},

		/**
		 * @param {Object}
		 */
		async buildAddressTypeOptionsList(assigned) {
			const _k = Object.keys(assigned)[0];
			const __ = getObjectFromValue(this.addressOptionsMap, 'transportField', _k);
			this.setAddressTypeSelected(assigned[_k]);

			if (isNil(__)) return;

			if (__.queryName !== 'address') {
				const list = await transportsQueries[__.queryName]();
				this.addressOptionsListMap = this.selectedTypeCleanListMap(__, list);
				this.setSelectedTypeOption(this.addressOptionsListMap);
			}
			this.setSelectedAddressType(__.code);
		},

		/**
		 * @param {Array} optionsList
		 */
		setSelectedTypeOption(optionsList) {
			const selected = getObjectFromValue(optionsList, 'addressId', this.directionPayload[this.transportAddressId]);
			if (isNil(selected)) return;
			this.selectedTypeOption = { code: selected.code, label: selected.label };
		},

		/**
		 * @param {Object} type
		 * @param {Array} list
		 * @return {Array}
		 */
		selectedTypeCleanListMap(type, list) {
			if (isNil(type) || isNil(list)) return;
			return list
				.filter((item) => item[type.objectGqlKey] !== 0)
				.map((item) => ({
					code: item.id,
					addressId: item[type.objectGqlKey],
					label: type.queryName === 'user' ? `${item.firstName} ${item.name}` : item.setName
				}));
		},

		/**
		 * @param {String|Number}
		 */
		setAddressTypeSelected(code) {
			if (isNil(code)) return;
			this.selectedTypeOption = getObjectFromValue(this.addressOptionsListMap, 'code', `${code}`);
		},

		resetData() {
			const addressKeys = possibleAddressKeys[this.type];

			this.addressKeys = addressKeys;
			this.addressOptions = objReducer(addressKeys, addressOptions);
			this.transportAddressId = transportTypeAddressIds[this.type];
			this.selectedAddress = null;
			this.selectedAddressType = addressOptions[addressKeys[0]];
			this.defaultAddressType = addressOptions[addressKeys[0]].code;

			this.directionPayload = Object.assign({}, payloadDefault);

			this.rendAddressSelected = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.middle {
	display: flex;
	align-items: center;
}
</style>
