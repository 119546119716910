var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.disabled ? 12 : 9,
                lg: _vm.disabled ? 12 : 9,
                xl: _vm.disabled ? 12 : 9,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(114, "Passengers") } },
                [
                  _c("tags-input", {
                    attrs: {
                      id: "tagsInputPassengers",
                      typeahead: "",
                      "typeahead-style": "dropdown",
                      "element-id": "users-tags-selection",
                      disabled: _vm.disabled,
                      placeholder: _vm.FormMSG(12312, "Add passengers"),
                      "existing-tags": _vm.passengersOptionsListMap,
                    },
                    on: {
                      "tag-added": _vm.handleTagAdded,
                      "tag-removed": _vm.handleTagRemoved,
                    },
                    model: {
                      value: _vm.selectedPassengersTag,
                      callback: function ($$v) {
                        _vm.selectedPassengersTag = $$v
                      },
                      expression: "selectedPassengersTag",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.disabled
            ? _c(
                "b-col",
                {
                  class: _vm.$screen.width > 567 ? "pt-28" : "",
                  attrs: { sm: "12", md: "3", lg: "3", xl: "3" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", block: "", disabled: _vm.disabled },
                      on: { click: _vm.handClickManagePassanger },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.FormMSG(198, "Manage passengers")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { visible: _vm.isManagePassengersModalOpen },
          on: {
            "update:visible": function ($event) {
              _vm.isManagePassengersModalOpen = $event
            },
          },
        },
        [
          _vm.$nil(_vm.selectedPassengersTag) ||
          _vm.selectedPassengersTag.length === 0
            ? _c("div", { staticClass: "alert" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(567865, "No passenger(s) selected")) +
                    "\n\t\t"
                ),
              ])
            : _c(
                "ul",
                { staticClass: "list-container" },
                _vm._l(_vm.selectedPassengersTag, function (passenger, i) {
                  return _c("li", { key: i, staticClass: "clearfix full" }, [
                    _c("div", { staticClass: "content_sb_middle" }, [
                      _c("span", [_vm._v(_vm._s(passenger.value))]),
                      _c(
                        "div",
                        { staticClass: "content_sb_middle_end" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "warning", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditPassenger(
                                    passenger.payload
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "icon-pencil" })]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-danger", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTagRemoved(passenger)
                                },
                              },
                            },
                            [_c("i", { staticClass: "icon-trash" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "inline_content centered_content full" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-block",
                    attrs: {
                      variant: "outline-success",
                      disabled: _vm.disabled,
                    },
                    on: { click: _vm.handleCreatePassenger },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(4567865, "Create passenger")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.transport
        ? _c("crud-passenger-modal", {
            attrs: { transport: _vm.transport, "edit-data": _vm.currPassenger },
            on: { "passenger-added": _vm.handleNewPassengerAdded },
            model: {
              value: _vm.isPassengerCrudModalOpen,
              callback: function ($$v) {
                _vm.isPassengerCrudModalOpen = $$v
              },
              expression: "isPassengerCrudModalOpen",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }