<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		size="xl"
		ok-variant="success"
		:title="FormMSG(111, 'Vehicule')"
		:ok-title="FormMSG(1, 'Save')"
		:cancel-title="FormMSG(2, 'Cancel')"
		@ok.prevent="handleSubmitModal"
		@cancel="handleCloseModal"
		@hidden="handleCloseModal"
	>
		<div class="form">
			<vehicule-form ref="vehiculeFormRef" :vehicule-data="vehiculesPayload" @vehicule-form:change="handleVehiculeFormChange" />
		</div>
	</b-modal>
</template>

<script>
import { isNil } from '@/shared/utils';
import { addUpdVehicule } from '@/cruds/vehicules.crud';
import languageMessages from '@/mixins/languageMessages';
import modalMixin from '@/mixins/modal.mixin';
import { validationMixin } from 'vuelidate';
import VehiculeForm from '@/modules/transports/components/VehiculeForm.vue';

const vehiculesDefault = {
	name: null,
	brand: null,
	model: null,
	numberPlate: null,
	type: 6,
	subType: 2,
	additionalSubType: 7,
	energy: null,
	reference: null,
	kgCoTwoPerKm: 0,
	taxHorsepower: 0,
	grayCardXid: '',
	grayCardVersoXid: ''
};

export default {
	name: 'VehiculesTransportModuleModal',
	mixins: [languageMessages, modalMixin, validationMixin],
	props: {
		editData: {
			type: Object,
			required: false,
			default: null
		},
		currSearch: {
			type: String,
			required: false,
			default: ''
		}
	},
	components: {
		VehiculeForm
	},
	data() {
		return {
			vehiculesPayload: Object.assign({}, vehiculesDefault)
		};
	},

	computed: {
		/**
		 * @return {Boolean}
		 */
		isEditMode() {
			return !isNil(this.editData);
		}
	},
	watch: {
		/**
		 * @param {Boolean}
		 */
		isModalOpen(status) {
			if (!status) return;
			this.initEditMode();
			this.initSearch();
		}
	},
	methods: {
		handleVehiculeFormChange(payload) {
			this.vehiculesPayload = {
				...payload
			};
		},
		initEditMode() {
			if (!this.isEditMode) return;
			this.vehiculesPayload = {
				...this.vehiculesPayload,
				...this.editData
			};
		},

		initSearch() {
			if (this.isEditMode || this.currSearch === '') return;
			this.vehiculesPayload.name = this.currSearch;
		},

		async handleSubmitModal() {
			const resultVehiculeFormValidation = this.$refs['vehiculeFormRef'].isInvalid();

			if (!resultVehiculeFormValidation) {
				const vehicule = await addUpdVehicule({
					vehiculeId: this.isEditMode ? this.editData.id : 0,
					payload: {
						...this.vehiculesPayload,
						kgCoTwoPerKm: parseInt(this.vehiculesPayload.kgCoTwoPerKm),
						taxHorsepower: parseFloat(this.vehiculesPayload.taxHorsepower),
						forProduction: true
					}
				});
				this.$emit('submit', vehicule);

				this.handleCloseModal();
			}
		},

		handleCloseModal() {
			this.vehiculesPayload = Object.assign({}, vehiculesDefault);
			this.$emit('input', false);
		}
	}
};
</script>
