import { isNil, makeID } from '~utils';
import LanguageMessages from '@/mixins/languageMessages';

export default {
	name: 'SelectorsMixin',
	mixins: [LanguageMessages],
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: null
		},
		options: {
			type: Array,
			required: false,
			default: () => []
		},
		label: {
			type: String,
			required: false,
			default: null
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Type to search ...'
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		keyField: {
			type: String,
			required: false,
			default: 'id'
		},
		labelField: {
			type: String,
			required: false,
			default: 'name'
		},
		/**
		 * e.g:
		 * [{key: '8ErxaY', function: ..., title: 'My tooltip content', item: {..., ..., ...}}]
		 */
		iconActions: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
			// isCanModify: false,
			currSearch: ''
		};
	},
	computed: {
		selectorRef() {
			return `selector_${makeID(5)}`;
		},
		/**
		 * @return {String}
		 */
		rendPlaceholder() {
			return isNil(this.placeholder) ? this.FormMSG(1, this.placeholder) : this.placeholder;
		},
		selected: {
			/**
			 * @return {String|Number}
			 */
			get() {
				return this.value;
			},
			/**
			 * @param {String|Number} selection
			 * @return {Emitter}
			 */
			set(selection) {
				this.$emit('input', selection);
			}
		}
	},
	methods: {
		/**
		 * @param {String} search
		 */
		handleSearch(search) {
			if (search === '') return;
			this.currSearch = search;
			this.$emit('search', search);
		}
	}
};
