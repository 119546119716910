var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transport-address-holder-component" },
    [
      _c(
        "b-row",
        { staticClass: "middle" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.formLabel } },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "selecting-address-type",
                      "value-field": "code",
                      "text-field": "label",
                      disabled: _vm.disabled,
                      placeholder: _vm.FormMSG(333, "Chose address mode"),
                      options: _vm.addressOptionsMap,
                    },
                    on: { change: _vm.handleAddressPayloadChange },
                    model: {
                      value: _vm.addressTypePayload,
                      callback: function ($$v) {
                        _vm.addressTypePayload = $$v
                      },
                      expression: "addressTypePayload",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isDefaultAddressType && !_vm.forMyAddress
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "9", lg: "9", xl: "9" } },
                _vm._l(_vm.addressFiller, function (item, i) {
                  return _c("DynamicAddress", {
                    key: i,
                    attrs: {
                      "hide-remove-btn": "",
                      "show-clear-address-btn": "",
                      params: item,
                      disabled: _vm.disabled,
                      "address-id":
                        _vm.directionPayload[_vm.transportAddressId],
                    },
                    on: { change: _vm.handleDynamicAddressChange },
                  })
                }),
                1
              )
            : _vm._e(),
          !_vm.isDefaultAddressType && !_vm.forMyAddress
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "9", lg: "9", xl: "9" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "pt-13" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "7", lg: "7", xl: "7" } },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.disabled || _vm.disableSelect,
                              placeholder: _vm.FormMSG(
                                39933,
                                "Chose address mode"
                              ),
                              options: _vm.addressOptionsListMap,
                            },
                            on: { input: _vm.handleAddressSelectedChange },
                            model: {
                              value: _vm.selectedTypeOption,
                              callback: function ($$v) {
                                _vm.selectedTypeOption = $$v
                              },
                              expression: "selectedTypeOption",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "5", lg: "5", xl: "5" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-inline-block",
                              class: { "mt-3": _vm.$screen.width < 576 },
                            },
                            [
                              _vm.directionPayload[_vm.transportAddressId] !== 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "address-selected fs-11 fw-700",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.rendAddressSelected) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _c("span", { staticClass: "fs-11 fw-700" }, [
                                    _vm._v("..."),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.forMyAddress
            ? _c("b-col", { attrs: { sm: "12", md: "9", lg: "9", xl: "9" } }, [
                _vm.rendAddressForMyAddress
                  ? _c(
                      "span",
                      { staticClass: "address-selected fs-14 fw-700" },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.rendAddressForMyAddress) +
                            "\n\t\t\t"
                        ),
                      ]
                    )
                  : _c("span", { staticClass: "fs-11 fw-700" }, [
                      _vm._v("..."),
                    ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }