<template>
	<div>
		<b-row>
			<b-col>
				<b-form-group :label="FormMSG(56, 'Vehicle')">
					<v-select
						v-model="selectedVehicule"
						:options="vehiculesOptionsMap"
						:disabled="disabled"
						class="mb-2"
						:placeholder="FormMSG(547, 'Select a vehicule')"
						@input="handleChange"
						@search="handleSearch"
					>
						<template #no-options="{ search, searching }">
							<template v-if="searching">
								{{ FormMSG(1, 'No results for') }}
								<em>{{ search }}</em>
								. {{ FormMSG(2, 'You can add it') }}.
							</template>
							<em v-else style="opacity: 0.5"> {{ FormMSG(3, 'Start typing to search for a vehicule') }}. </em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col v-if="canAddAndEdit && canDoModification" cols="4">
				<div class="pt-28 select_items_filter_wrapper" :class="{ has_two_btn: isCanModifyVehicule }">
					<div class="actions_holder">
						<div class="supplier_actions_btns has_two_btn">
							<b-button size="sm" :disabled="disabled" @click="handleClickAddButton">
								<component
									v-if="!isCanModifyVehicule"
									:is="getLucideIcon('PlusCircle')"
									color="#FFFFFF"
									:size="18"
									style="margin-bottom: 2px"
								/>
								<component v-if="isCanModifyVehicule" :is="getLucideIcon('Edit')" color="#FFFFFF" :size="18" style="margin-bottom: 2px" />
							</b-button>
							<b-button size="sm" :disabled="disabled" block variant="outline-primary" @click="handleClickAddButton">
								{{ isCanModifyVehicule ? FormMSG(125, 'Edit') : FormMSG(2332234, 'Add') }}
							</b-button>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>

		<VehiculesModal v-model="isCarCrudCarModalOpen" :edit-data="currVehiculeEditData" :curr-search="currSearch" @submit="handleVehiculeSubmit" />
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import { getVehicule, getVehicules } from '@/cruds/vehicules.crud';
import languageMessages from '@/mixins/languageMessages';
import selectorsMixin from '@/mixins/selectors.mixin';
import GlobalMixin from '@/mixins/global.mixin';

import VehiculesModal from '@/modules/transports/Modals/VehiculesModal';

export default {
	name: 'VehiculesTransportModuleComponent',
	components: { VehiculesModal },
	mixins: [selectorsMixin, languageMessages, GlobalMixin],
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		canAddAndEdit: {
			type: Boolean,
			required: false,
			default: true
		},
		userId: {
			type: [Number, String],
			default: 0,
			required: false
		}
	},
	data() {
		return {
			selectedVehicule: null,
			currSearch: '',
			vehiculesOptionsList: [],
			canDoModification: true,
			isCarCrudCarModalOpen: false,
			isCanModifyVehicule: false,
			currVehiculeEditData: null,

			userIdVehicule: 0
		};
	},
	watch: {
		canAddAndEdit: {
			handler(newVal) {
				this.canDoModification = newVal;
			},
			immediate: true,
			deep: true
		},
		'editData.userId': {
			handler(newVal) {
				this.selectedVehicule = null;
			},
			deep: true
		},
		userId: {
			async handler(newVal) {
				if (newVal > 0) {
					this.userIdVehicule = parseInt(newVal);
					await this.initVehiculesOptionsList();
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		vehiculesOptionsMap() {
			return this.vehiculesOptionsList.map((v) => ({
				code: v.id,
				label: `${v.name} (${v.numberPlate})`,
				type: v.type,
				subType: v.subType,
				additionalSubType: v.additionalSubType,
				forProduction: v.forProduction
			}));
		},

		vehiculeSelected: {
			/**
			 * @return {String|Number}
			 */
			get() {
				return this.vehiculeId;
			},
			/**
			 * @param {Object} item
			 * @return {Emitter}
			 */
			set({ code }) {
				this.$emit('change', code);
			}
		}
	},
	created() {
		this.initVehiculesOptionsList();
		this.initSelection();
	},
	methods: {
		handleClickAddButton() {
			if (this.isCanModifyVehicule) {
				this.openEditVehiculeModal();
			} else {
				this.openAddVehiculeModal();
			}
		},
		async initSelection() {
			if (isNil(this.editData) || this.editData.vehiculeId === 0) return;
			const vehicule = await getVehicule(this.editData.vehiculeId);
			this.setSelectedVehicule(vehicule);
		},

		async initVehiculesOptionsList() {
			this.vehiculesOptionsList = await getVehicules(true, this.userIdVehicule);
		},

		/**
		 * @param payload
		 */
		setSelectedVehicule(payload) {
			if (!isNil(payload)) {
				this.selectedVehicule = {
					code: payload.id,
					label: payload.name
				};
				this.isCanModifyVehicule = true;
			}
		},

		/**
		 * @param {Object} vehicule
		 */
		async handleVehiculeSubmit(vehicule) {
			this.setSelectedVehicule(vehicule);
			await this.handleChange({ code: vehicule.id });
			await this.initVehiculesOptionsList();
			this.isCanModifyVehicule = true;
		},

		async openEditVehiculeModal() {
			if (isNil(this.selectedVehicule)) return;
			const { code } = this.selectedVehicule;
			this.currVehiculeEditData = await getVehicule(code);
			this.isCarCrudCarModalOpen = true;
		},

		openAddVehiculeModal() {
			this.isCanModifyVehicule = false;
			this.currVehiculeEditData = null;
			this.isCarCrudCarModalOpen = true;
		},

		/**
		 * @param {Object} $event
		 */
		async handleChange(payload) {
			if (isNil(payload)) {
				this.isCanModifyVehicule = false;
				this.$emit('change', payload);
			} else {
				this.canDoModification = payload.forProduction;
				// this.setSelectedVehicule({ id: parseInt(code), name: label });
				this.isCanModifyVehicule = true;
				this.$emit('change', payload.code);
			}
		},

		/**
		 * @param {String} search
		 */
		handleSearch(search) {
			if (search === '') return;
			this.currSearch = search;
			this.$emit('search', search);
		}
	}
};
</script>
