var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-address-field--component-wrapper" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.disabled ? 12 : 8,
                lg: _vm.disabled ? 12 : 8,
                xl: _vm.disabled ? 12 : 8,
              },
            },
            [
              _c("div", {
                staticClass: "fs-14 fw-700",
                domProps: { innerHTML: _vm._s(_vm.rendFilledAddress) },
              }),
            ]
          ),
          !_vm.disabled
            ? _c(
                "b-col",
                {
                  class: { "mt-2": _vm.$screen.width < 576 },
                  attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "success mr-2",
                        size: "sm",
                        disabled: _vm.disabled,
                      },
                      on: { click: _vm.openAdresseModal },
                    },
                    [
                      _c(
                        _vm.getLucideIcon(
                          _vm.checkAddress ? "Edit" : "PlusCircle"
                        ),
                        { tag: "component", attrs: { size: 16 } }
                      ),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.checkAddress
                              ? _vm.FormMSG(2, "Edit")
                              : _vm.FormMSG(1, "Add")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.hideRemoveBtn
                    ? _c(
                        "b-button",
                        {
                          attrs: { block: "", variant: "danger", size: "sm" },
                          on: { click: _vm.handleDeleteAddress },
                        },
                        [
                          _c(_vm.getLucideIcon("Trash2"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.FormMSG(3, "Remove")) +
                              "\n\t\t\t\t"
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.rendAddressTitle,
            size: "xl",
            "ok-variant": "success",
            disabled: _vm.disabled,
            "ok-title": _vm.FormMSG(34223434, "Save"),
            "cancel-title": _vm.FormMSG(9878667, "Cancel"),
          },
          on: {
            ok: _vm.handleAddressSubmit,
            cancel: _vm.handleAdressModalCancel,
            hidden: _vm.handleAdressModalCancel,
          },
          model: {
            value: _vm.isAddressModalOpen,
            callback: function ($$v) {
              _vm.isAddressModalOpen = $$v
            },
            expression: "isAddressModalOpen",
          },
        },
        [
          _c("AddressForm", {
            attrs: {
              "show-clear-address-btn": _vm.showClearAddressBtn,
              "edit-data": _vm.editAddressValue,
            },
            on: { change: _vm.handleAdressChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }