var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transport-drvier-selector--component-wrapper" },
    [
      _c("v-select", {
        staticClass: "mb-2",
        attrs: { options: _vm.driversOptionsMap, disabled: _vm.isStarted },
        on: { input: _vm.handleChange, search: _vm.handleSearch },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({ search, searching }) {
              return [
                searching
                  ? [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "No results for")) +
                          "\n\t\t\t\t"
                      ),
                      _c("em", [_vm._v(_vm._s(search))]),
                      _vm._v(
                        "\n\t\t\t\t. " +
                          _vm._s(_vm.FormMSG(2, "You can add it")) +
                          ".\n\t\t\t"
                      ),
                    ]
                  : _c("em", { staticStyle: { opacity: "0.5" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.FormMSG(
                              3,
                              "Start typing to search for a vehicule"
                            )
                          ) +
                          ". "
                      ),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedDriver,
          callback: function ($$v) {
            _vm.selectedDriver = $$v
          },
          expression: "selectedDriver",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }