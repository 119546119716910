<template>
	<div>
		<b-row>
			<b-col sm="12" :md="disabled ? 12 : 9" :lg="disabled ? 12 : 9" :xl="disabled ? 12 : 9">
				<b-form-group :label="FormMSG(114, 'Passengers')">
					<tags-input
						v-model="selectedPassengersTag"
						id="tagsInputPassengers"
						typeahead
						typeahead-style="dropdown"
						element-id="users-tags-selection"
						:disabled="disabled"
						:placeholder="FormMSG(12312, 'Add passengers')"
						:existing-tags="passengersOptionsListMap"
						@tag-added="handleTagAdded"
						@tag-removed="handleTagRemoved"
					/>
				</b-form-group>
			</b-col>
			<b-col v-if="!disabled" sm="12" md="3" lg="3" xl="3" :class="$screen.width > 567 ? 'pt-28' : ''">
				<b-button size="sm" block :disabled="disabled" @click="handClickManagePassanger"> {{ FormMSG(198, 'Manage passengers') }} </b-button>
			</b-col>
		</b-row>

		<modal :visible.sync="isManagePassengersModalOpen">
			<div v-if="$nil(selectedPassengersTag) || selectedPassengersTag.length === 0" class="alert">
				{{ FormMSG(567865, 'No passenger(s) selected') }}
			</div>
			<ul v-else class="list-container">
				<li v-for="(passenger, i) in selectedPassengersTag" :key="i" class="clearfix full">
					<div class="content_sb_middle">
						<span>{{ passenger.value }}</span>
						<div class="content_sb_middle_end">
							<b-button variant="warning" size="sm" @click="handleEditPassenger(passenger.payload)">
								<i class="icon-pencil" />
							</b-button>
							<b-button variant="outline-danger" size="sm" @click="handleTagRemoved(passenger)">
								<i class="icon-trash" />
							</b-button>
						</div>
					</div>
				</li>
			</ul>
			<template slot="footer">
				<div class="inline_content centered_content full">
					<b-button class="btn-block" variant="outline-success" :disabled="disabled" @click="handleCreatePassenger">
						{{ FormMSG(4567865, 'Create passenger') }}
					</b-button>
				</div>
			</template>
		</modal>
		<crud-passenger-modal
			v-if="transport"
			v-model="isPassengerCrudModalOpen"
			:transport="transport"
			:edit-data="currPassenger"
			@passenger-added="handleNewPassengerAdded"
		/>
	</div>
</template>

<script>
import { isNil, getObjectFromValue, objReducer } from '~utils';
import { getUser } from '@/cruds/users.crud';
import { getPassengers, delPassenger, addUpdPassenger, getUsersAndPassengersOfProject } from '@/cruds/transports.crud';
import { userName } from '@/shared/fomaters';
import languageMessages from '@/mixins/languageMessages';
import DriverSelector from '@/modules/transports/components/DriverSelector';
import CrudPassengerModal from '@/modules/transports/Modals/CrudPassengerModal';

export default {
	name: 'DriverAndPassengerTrasnportServiceComponent',
	components: { DriverSelector, CrudPassengerModal },
	mixins: [languageMessages],
	props: {
		transport: {
			type: Object,
			required: true,
			default: () => {}
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			passengersList: [],
			usersList: [],
			driversList: [],
			selectedPassengersTag: [],
			isNewUserModalOpen: false,
			isDriverModalOpen: false,
			isManagePassengersModalOpen: false,
			isSelectedPassengersLoaded: false,
			currPassenger: null,
			currDriver: null,

			isPassengerCrudModalOpen: false
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		passengersOptionsListMap() {
			return this.mapUsersList(this.usersList);
		},

		/**
		 * @return {Array}
		 */
		vehiculesListMap() {
			return this.driversList.map((u) => Object.assign(u, { completeName: u.firstName + ' ' + u.name }));
		}
	},
	async created() {
		await this.initPassengersSelectionList();
		this.loadPassengers();
		this.setCurrDriver(this.transport.userId);
	},
	methods: {
		async setCurrDriver(driverId) {
			this.currDriver = await getUser(driverId);
		},

		async initPassengersSelectionList() {
			this.usersList = await getUsersAndPassengersOfProject();
		},

		async loadPassengers() {
			this.isSelectedPassengersLoaded = false;

			const passengers = await getPassengers(this.transport.id);
			this.passengersList = passengers;
			this.setSelectedPassengers(passengers);

			this.isSelectedPassengersLoaded = true;
		},

		/**
		 * @param {Array}
		 */
		setSelectedPassengers(passengers) {
			if (isNil(passengers)) return;
			this.selectedPassengersTag = this.mapUsersList(passengers);
		},

		/**
		 * @param {Array} users
		 * @return {Array}
		 */
		mapUsersList(users) {
			return users.map((u) => {
				const _p = isNil(u.phone) || u.phone === '' ? '' : `(${u.phone})`;
				return {
					key: u.id,
					value: [userName(u), _p].join(' '),
					payload: u
				};
			});
		},

		/**
		 * @param {Object} user
		 */
		async handleTagAdded(user) {
			const pId = getObjectFromValue(this.passengersList, 'id', user.payload.id);

			await addUpdPassenger({
				transportId: this.transport.id,
				passengerId: isNil(pId) ? 0 : user.payload.id,
				updatedPassenger: objReducer(['firstName', 'name', 'phone'], user.payload)
			});
		},

		/**
		 * @param {Object} user
		 */
		async handleTagRemoved(user) {
			if (isNil(user)) return;
			await delPassenger(parseInt(user.key));
		},

		/**
		 * @param {Object} userPayload
		 */
		openPassengerModal(userPayload) {
			this.currPassenger = userPayload;
			this.isNewUserModalOpen = true;
		},

		async handleNewPassengerAdded() {
			await this.loadPassengers();
		},

		/**
		 * @param {String|Number} driverId
		 * @return {Emitter}
		 */
		handleDriverChange(driverId) {
			this.setCurrDriver(driverId);
			this.$emit('driver-change', driverId);
		},

		handClickManagePassanger() {
			this.isManagePassengersModalOpen = true;
		},

		/**
		 * @param {Object} passenger
		 */
		handleEditPassenger(passenger) {
			this.currPassenger = passenger;
			this.isPassengerCrudModalOpen = true;
		},

		/**
		 * @param {Object} passenger
		 */
		handleCreatePassenger() {
			this.currPassenger = null;
			this.isPassengerCrudModalOpen = true;
		}
	}
};
</script>
