<template>
	<div class="transport-drvier-selector--component-wrapper">
		<v-select v-model="selectedDriver" :options="driversOptionsMap" :disabled="isStarted" class="mb-2" @input="handleChange" @search="handleSearch">
			<template #no-options="{ search, searching }">
				<template v-if="searching">
					{{ FormMSG(1, 'No results for') }}
					<em>{{ search }}</em>
					. {{ FormMSG(2, 'You can add it') }}.
				</template>
				<em v-else style="opacity: 0.5"> {{ FormMSG(3, 'Start typing to search for a vehicule') }}. </em>
			</template>
		</v-select>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import { getUsers, getUser } from '@/cruds/users.crud';

export default {
	name: 'DriverSelectorTransportModuleComponent',
	props: {
		isStarted: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			driversList: [],
			selectedDriver: null
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		driversOptionsMap() {
			return this.driversList.map((u) => ({
				code: u.id,
				label: `${u.firstName} ${u.name}`
			}));
		}
	},
	async created() {
		await this.loadDrivers();
		await this.initSelection();
	},
	methods: {
		async initSelection() {
			if (isNil(this.editData) || this.editData.userId === 0) return;
			const driver = await getUser(this.editData.userId);
			this.setSelectedDriver(driver);
		},

		async loadDrivers() {
			this.driversList = await getUsers({ myProject: true });
		},

		/**
		 * @param supplier
		 */
		setSelectedDriver(driver) {
			this.selectedDriver = {
				code: driver.id,
				label: `${driver.firstName} ${driver.name}`
			};
		},

		/**
		 * @param {Object} $event
		 */
		async handleChange({ code }) {
			if (isNil(code)) return;
			this.$emit('change', code);
		},

		/**
		 * @param {String} search
		 */
		handleSearch(search) {
			if (search === '') return;
			this.currSearch = search;
			this.$emit('search', search);
		}
	}
};
</script>
